<template>
  <transition name="fade">
    <div
      v-if="show"
      class="loader"
      :style="{ position }"
    >
      <b-spinner
        variant="primary"
        label="Loading..."
        :style="spinnerStyleObj"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PageLoader',
  props: {
    show: {
      default: false,
      required: false
    },
    position: {
      type: String,
      default: 'fixed'
    },
    small: Boolean,
  },
  computed: {
    spinnerStyleObj() {
      return {
        width: this.small ? '2rem' : '4rem',
        height: this.small ? '2rem' : '4rem',
        borderWidth: this.small ? '0.2rem' : '0.4rem'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .loader {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1003;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#fff, 0.3);
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>